<template>
    <div>
        <header-caller @clickToggle="toggle"></header-caller>
        <div class="row border-top border-bottom">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-12 py-3">
                        <h3 class="text-uppercase text-truncate">
                            {{
                                unitQueue ? unitQueue : "Silahkan pilih counter"
                            }}
                        </h3>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-lg-5 col-sm-5 col-auto">
                                        <div class="d-flex">
                                            <div class="mr-2">
                                                <span
                                                    class="display-4 kt-font-boldest"
                                                    >{{
                                                        selectedQueue
                                                            ? `${
                                                                  selectedQueue.queueNoFormatted
                                                              }`
                                                            : "-"
                                                    }}</span
                                                >
                                            </div>
                                            <div
                                                class=" m-auto "
                                                v-if="
                                                    selectedQueue &&
                                                        selectedQueue.queueStatus ==
                                                            'SERVING'
                                                "
                                            >
                                                <p
                                                    class="kt-font-lg kt-font-bold m-0  "
                                                >
                                                    Dilayani <br />
                                                     <template v-if="selectedQueue.lastCheckInDate">
                                                    {{
                                                        selectedQueue.lastCheckInDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                                    </template>
                                                    <template v-else>
                                                    {{
                                                        selectedQueue.lastModifiedDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                                    </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-lg-7 col-sm-7 col-auto mt-3"
                                    >
                                        <div
                                            class="float-right"
                                            v-if="
                                                selectedQueue &&
                                                    selectedQueue.queueStatus ==
                                                        'CHECKEDIN'
                                            "
                                        >
                                            <div v-if="selectedQueue">
                                                <button
                                                    v-if="
                                                        !isLoading &&
                                                            selectedQueue
                                                    "
                                                    class="btn btn-default text-uppercase "
                                                    @click="checkPrinter(2)"
                                                >
                                                    <i class="fas fa-print"></i>
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn btn-default text-uppercase "
                                                    type="button"
                                                    disabled
                                                >
                                                    <span
                                                        class="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                </button>
                                                <button
                                                    v-if="
                                                        !isLoading &&
                                                            selectedQueue
                                                    "
                                                    class="btn btn-default text-uppercase modalNote "
                                                    @click="
                                                        setShowModalNote(true)
                                                    "
                                                >
                                                    <i
                                                        class="far fa-sticky-note"
                                                    ></i>
                                                    <sup
                                                        v-if="
                                                            selectedQueue.note
                                                        "
                                                    >
                                                        <span
                                                            class="badge badge-pill badge-danger"
                                                            >I</span
                                                        >
                                                    </sup>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row queueNote">
                                    <div class="col-12">
                                        <textarea
                                            class="form-control"
                                            rows="3"
                                            v-if="selectedQueue"
                                            v-model="selectedQueue.note"
                                        ></textarea>
                                        <textarea
                                            class="form-control"
                                            rows="3"
                                            v-else
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-1">
                                <div class="row">
                                    <div class="col-12">
                                        <button
                                            class="btn btn-default text-uppercase w-100"
                                            @click="
                                                updateQueue(selectedQueue, 1)
                                            "
                                        >
                                            <i class="la la-bullhorn"></i>
                                            Panggil
                                        </button>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-4">
                                        <button
                                            class="btn btn-default text-uppercase w-100"
                                            @click="
                                                updateQueue(selectedQueue, 2)
                                            "
                                        >
                                            Layani
                                        </button>
                                    </div>
                                    <div class="col-4">
                                        <button
                                            class="btn btn-default text-uppercase w-100"
                                            @click.prevent="
                                                updateQueue(selectedQueue, 3)
                                            "
                                        >
                                            Selesai
                                        </button>
                                    </div>
                                    <div class="col-4">
                                        <button
                                            class="btn btn-default text-uppercase w-100"
                                            @click.prevent="callingNextQueue()"
                                        >
                                            Berikutnya
                                        </button>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-4">
                                        <button
                                            class="btn btn-default text-uppercase w-100"
                                            @click="
                                                updateQueue(selectedQueue, 4)
                                            "
                                        >
                                            Lewati
                                        </button>
                                    </div>
                                    <div class="col-4">
                                        <button
                                            class="btn btn-default text-uppercase w-100"
                                            @click.prevent="showPindah()"
                                        >
                                            Pindah
                                        </button>
                                    </div>
                                    <div class="col-4">
                                        <button
                                            v-if="!isLoading"
                                            class="btn btn-default text-uppercase w-100"
                                            @click="checkPrinter(1)"
                                        >
                                            Cetak Baru
                                        </button>
                                        <button
                                            v-else
                                            class="btn btn-default text-uppercase w-100"
                                            type="button"
                                            disabled
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border-top caller-list-antrian">
                    <h6 class="kt-font-bold my-0">No. Antrian :</h6>
                    <div class="col-12 pt-2">
                        <div
                            class="d-flex py-3 align-items-center kt-scroll"
                            id="listAntrian"
                        >
                            <div
                                class="pointer btn-list-caller m-2  col-md-4 col-lg-2 "
                                v-for="n in checkedinQueueResource"
                                :key="n.queueId"
                            >
                                <label
                                    class="btn-list-caller-content btn btn-default pointer btn-kotak"
                                    v-if="!selectedQueue"
                                >
                                    <div class="icon_antrian">
                                        <i
                                            :class="getType(n.queueType)"
                                            style="font-size: 0.75rem"
                                        ></i>
                                        <i
                                            :class="getClass(n.queueStatus)"
                                            style="font-size: 0.75rem"
                                        ></i>
                                    </div>
                                    <input
                                        type="radio"
                                        class="d-none"
                                        :value="n"
                                        @click="eventClickBox(n)"
                                    />
                                    <div class="btn-kotak-content">
                                        {{ n.queueNoFormatted }}
                                    </div>
                                    <span class="count-timer" >
                                        <template v-if="n.lastCheckInDate">
                                                    {{
                                                        n.lastCheckInDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                                    </template>
                                                    <template v-else>
                                                    {{
                                                        n.lastModifiedDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                        </template>
                                    </span>
                                </label>
                                <label
                                    class="btn-list-caller-content pointer btn btn-default btn-kotak"
                                    v-else
                                    :class="
                                        selectedQueue.queueNoFormatted ==
                                        n.queueNoFormatted
                                            ? 'active'
                                            : ''
                                    "
                                >
                                    <div class="icon_antrian">
                                        <i
                                            :class="getType(n.queueType)"
                                            style="font-size: 0.75rem"
                                        ></i>
                                        <i
                                            :class="getClass(n.queueStatus)"
                                            style="font-size: 0.75rem"
                                        ></i>
                                    </div>
                                    <input
                                        type="radio"
                                        class="d-none"
                                        :value="n"
                                        @click="eventClickBox(n)"
                                    />
                                    <div class="btn-kotak-content ">
                                        {{ n.queueNoFormatted }}
                                    </div>
                                    <span class="count-timer">
                                        <template v-if="n.lastCheckInDate">
                                                    {{
                                                        n.lastCheckInDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                                    </template>
                                                    <template v-else>
                                                    {{
                                                        n.lastModifiedDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                        </template>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 caller-unit-border">
                <div class="row">
                    <div class="col-12 py-3 border-bottom">
                        <h5 class="text-center">PANGGILAN MANUAL</h5>
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukan Nomor Antrian"
                                v-model="nomorAntrian"
                                @keyup.enter="callManual(nomorAntrian)"
                            />
                            <div class="input-group-append">
                                <button
                                    class="btn btn-default btn-bold"
                                    type="button"
                                    @click="callManual(nomorAntrian)"
                                >
                                    <i class="la la-bullhorn"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 py-3 caller-list-antrian">
                        <h5 class="text-center">
                            ANTRIAN DIPANGGIL ATAU DILAYANI
                        </h5>
                        <div
                            class="row kt-scroll unit-caller-list-dilayani"
                            id="antrianLayani"
                        >
                            <div
                                class="card-antrian"
                                v-for="n in callingQueueResource"
                                :key="n.queueId"
                            >
                                <label
                                    v-if="!selectedQueue"
                                    class="btn mr-2 btn-default btn-kotak pointer"
                                >
                                    <div class="icon_antrian">
                                        <i
                                            :class="getType(n.queueType)"
                                            style="font-size: 0.75rem"
                                        ></i>
                                        <i
                                            :class="getClass(n.queueStatus)"
                                            style="font-size: 0.75rem"
                                        ></i>
                                    </div>
                                    <input
                                        type="radio"
                                        class="d-none"
                                        :value="n"
                                        @click="eventClickBox(n)"
                                    />
                                    <div class="btn-kotak-content">
                                        {{ n.queueNoFormatted }}
                                    </div>
                                    <span class="count-timer queue-timer">
                                        <template v-if="n.lastCheckInDate">
                                                    {{
                                                        n.lastCheckInDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                                    </template>
                                                    <template v-else>
                                                    {{
                                                        n.lastModifiedDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                        </template>
                                    </span>
                                </label>
                                <label
                                    v-else
                                    class="btn mr-2 btn-default btn-kotak pointer"
                                    :class="
                                        selectedQueue.id == n.id ? 'active' : ''
                                    "
                                >
                                    <div class="icon_antrian">
                                        <i
                                            :class="getType(n.queueType)"
                                            style="font-size: 0.75rem"
                                        ></i>
                                        <i
                                            :class="getClass(n.queueStatus)"
                                            style="font-size: 0.75rem"
                                        ></i>
                                    </div>
                                    <input
                                        type="radio"
                                        class="d-none"
                                        :value="n"
                                        @click="eventClickBox(n)"
                                    />
                                    <div class="btn-kotak-content">
                                        {{ n.queueNoFormatted }}
                                    </div>
                                    <span class="count-timer queue-timer">
                                        <template v-if="n.lastCheckInDate">
                                                    {{
                                                        n.lastCheckInDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                                    </template>
                                                    <template v-else>
                                                    {{
                                                        n.lastModifiedDate
                                                            | formatDate(
                                                                nowTime
                                                            )
                                                    }}
                                        </template>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <h6
                            class="text-center kt-font-info mt-3"
                            v-if="selectedQueue == null"
                        >
                            Antrian terpilih : -
                        </h6>
                        <h6 class="text-center kt-font-info mt-3" v-else>
                            Antrian terpilih :
                            {{
                                selectedQueue
                                    ? `${selectedQueue.queueNoFormatted}`
                                    : `-`
                            }}
                        </h6>
                        <button
                            type="button"
                            class="btn btn-danger btn-bold w-100"
                            @click="setSelectedQueue(null)"
                        >
                            Clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ModalHistory v-if="showHistory"></ModalHistory>
        <DivToPrint
            :detailPrint="detailPrint"
            :mode-print="'unit'"
            ref="detailPrint"
        ></DivToPrint>
        <ModalPindah
            v-if="showModalPindah"
            :value="selectedQueue"
        ></ModalPindah>
        <ModalHelp v-if="showModalHelp"></ModalHelp>
        <ModalNote v-if="showModalNote"></ModalNote>
        <ModalPrinter
            v-if="showModalPrinter"
            :payload="payloadPrint"
            @print="print"
        ></ModalPrinter>
    </div>
</template>

<script>
import { Printd } from "printd";
import PrintBilling from "../../transaksi_referrals/TransaksiReferralPrint";
import HeaderCaller from "./_components/HeaderCaller";
import { mapState, mapMutations, mapActions } from "vuex";
import CallerType from "./../../../constants/caller-type-enum";
import QueueDevice from "./../../../model/queue-device-model";
import UnitRepository from "./../../../repositories/UnitRepository";
import QueueStatus from "./../../../constants/queue-resource-enum";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const QueueResource = RepositoryFactory.get("queueUser");
const ServerTimestampRepository = RepositoryFactory.get("serverTimestamp");

import DivToPrint from "../../_general/DivToPrint.vue";

import QueueResourceRepository from "../../../repositories/QueueResourceRepository";
const { RSAKey, KJUR, KEYUTIL, stob64, hextorstr } = require("jsrsasign");

export default {
    components: {
        HeaderCaller,
        DivToPrint,
        ModalPindah: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-pindah" */ "../DoctorCallers/ModalPindah/ModalPindah")
                    );
                }, 1000);
            })
        }),
        ModalHistory: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-history" */ "./_components/ModalHistory.vue")
                    );
                }, 1000);
            })
        }),
        ModalPrinter: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(() => {
                    resolve(
                        import(/* webpackChunkName: "modal-printer" */ "../../_select/SelectPrinter.vue")
                    );
                }, 1000);
            })
        }),
        ModalHelp: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-help" */ "./_components/ModalHelp")
                    );
                }, 1000);
            })
        }),
        ModalNote: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-help" */ "./_components/ModalNote")
                    );
                }, 1000);
            })
        })
    },
    data() {
        return {
            detailPrint: {
                displayName1: "",
                displayName2: "",
                queueNo: "",
                date: "",
                content: "empty"
            },
            nomorAntrian: "",
            note: "",
            jam: 0,
            menit: 0,
            detik: 0,
            zero1: 0,
            zero1: 0,
            zero3: 0,
            qrProps: {
                margin: 0,
                width: 80,
                errorCorrectionLevel: "M",
                quality: 1
            },
            payloadPrint: {
                printD: false,
                printSelected: null
            },
            nowTime: new Date()
        };
    },
    filters: {
        formatDate(date, now) {
            let Timenow = moment(now);
            let end = moment(date);
            let duration = moment.duration(Timenow.diff(end));

            let jam = duration.hours();
            let menit = duration.minutes();
            let detik = duration.seconds();

            jam < 10 ? (jam = "0" + jam) : jam;
            menit < 10 ? (menit = "0" + menit) : menit;
            detik < 10 ? (detik = "0" + detik) : detik;

            return `${jam}:${menit}:${detik}`;
        }
    },
    computed: {
        ...mapState("UnitCallerStore", {
            unitId: state => state.unit,
            unitCallerName: state => state.unitCallerName,
            checkedinQueueResource: state => state.checkedinQueueResource,
            callingQueueResource: state => state.callingQueueResource,
            showHistory: state => state.showHistory,
            showLoading: state => state.showLoading,
            selectedQueue: state => state.selectedQueue,
            historyUnitQueue: state => state.historyUnitQueue,
            showModalHelp: state => state.showModalHelp,
            showModalNote: state => state.showModalNote,
            unit: state => state.unit
        }),
        ...mapState("DoctorCallerStore", {
            showModalPindah: state => state.showModalPindah,
            serverTime: state => state.serverTime
        }),
        ...mapState("CallerStore", {
            userNotification: state => state.userNotification,
            sessionId: state => state.sessionId,
            viewCaller: state => state.viewCaller,
            unitQueue: state => state.unitQueue,
            queueDevice: state => state.queueDevice,
            showModalPrinter: state => state.showModalPrinter,
            defaultPrinter: state => state.defaultPrinter,
            isLoading: state => state.isLoading,
            printingOption: state => state.printingOption
        })
    },
    methods: {
        ...mapMutations({
            setSelectedUnit: "UnitCallerStore/SET_SELECTED_UNIT",
            setUnitCallerName: "UnitCallerStore/SET_UNIT_CALLER_NAME",
            setShowModalPindah: "DoctorCallerStore/SET_SHOW_MODAL_PINDAH",
            setShowHistory: "UnitCallerStore/SET_SHOW_HISTORY",
            setSelectedQueue: "UnitCallerStore/SET_SELECTED_QUEUE",
            setShowModalNote: "UnitCallerStore/SET_SHOW_MODAL_NOTE",
            setListPrinter: "CallerStore/SET_LIST_PRINTER",
            setDefaultPrinter: "CallerStore/SET_DEFAULT_PRINTER",
            setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
            setLoading: "CallerStore/SET_LOADING",
            setPrintingOption: "CallerStore/SET_PRINTING_OPTION"
        }),
        ...mapActions({
            saveQueueDevice: "CallerStore/SAVE_QUEUE_DEVICE",
            updateQueueStatus: "UnitCallerStore/UPDATE_QUEUE_STATUS",
            getServerTime: "DoctorCallerStore/GET_SERVER_TIME",
            getHistoryQueue: "UnitCallerStore/GET_HISTORY_QUEUE",
            postManualCallResource: "UnitCallerStore/POST_MANUAL_QUEUE",
            SOCK_MSG: "DoctorAppointmentStore/SOCK_MSG"
        }),
        onInit() {
            let device =
                sessionStorage.getItem("queueDevice") == undefined
                    ? new QueueDevice()
                    : { ...JSON.parse(sessionStorage.getItem("queueDevice")) };
            device.dokters = [];
            if (sessionStorage.getItem("selectedUnit") != undefined) {
                const selectedUnit = sessionStorage.getItem("selectedUnit");
                this.setSelectedUnit(selectedUnit);
                device.queueUnits = [
                    {
                        id: `U${selectedUnit}`
                    }
                ];
            }
            const callerName =
                sessionStorage.getItem("unitCallerName") != undefined
                    ? sessionStorage.getItem("unitCallerName")
                    : this.unitCallerName;
            this.setUnitCallerName(callerName);
            if (device.id == null) {
                device.notificationId =
                    this.userNotification != null
                        ? this.userNotification.notificationId
                        : null;
                device.uniqueId = this.sessionId;
                device.friendlyName = callerName;
            } else {
                device.friendlyName = callerName;
            }
            this.saveQueueDevice(device);
        },
        updateQueue(value, type) {
            if (value) {
                const data = {
                    note: value.note,
                    queueId: value.id,
                    uniqueId: this.queueDevice.uniqueId,
                    prevQueueId: value.id,
                    unitId: value.queueUnit.id
                };
                this.updateQueueStatus({
                    data,
                    type
                });
                // this.SOCK_MSG({
                // page: sessionStorage.getItem('viewCaller'),
                // sessionId: sessionStorage.getItem('selectedUnit')
                // })
            } else {
                toastr.error("Belum Memilih Antrian");
            }

            if (type > 2) {
                this.setSelectedQueue(null);
            }
        },
        callingNextQueue() {
            if (this.checkedinQueueResource.length != 0) {
                const payload = {
                    queueId: this.checkedinQueueResource[0].id,
                    uniqueId: this.queueDevice.uniqueId
                };

                QueueResourceRepository.postCallResource(payload);
            }
        },
        showPindah() {
            if (!this.selectedQueue) {
                toastr.error("Belum Memilih Antrian");
            } else {
                this.setShowModalPindah(true);
            }
        },
        async checkPrinter(num) {
            let vx = this;
            if (vx.unit) {
                vx.setLoading(true);
                if (vx.printingOption == "SERVER") {
                    if (num == 1) {
                        await helpers
                            .checkPrinter()
                            .then(data => {
                                if (data.selectPrinter) {
                                    vx.setShowModalPrinter(true);
                                } else {
                                    vx.print({ printD: false });
                                }
                            })
                            .catch(err => {
                                vx.print({ printD: true });
                            });
                    } else {
                        vx.payloadPrint.printSelected = vx.selectedQueue;
                        await helpers
                            .checkPrinter()
                            .then(data => {
                                if (data.selectPrinter) {
                                    vx.setShowModalPrinter(true);
                                } else {
                                    vx.printSelectedQueue(vx.payloadPrint);
                                }
                            })
                            .catch(err => {
                                vx.payloadPrint.printD = true;
                                vx.printSelectedQueue(vx.payloadPrint);
                            });
                    }
                } else {
                    vx.payloadPrint.printD = true;
                    if (num == 1) {
                        vx.print(vx.payloadPrint);
                    } else {
                        vx.payloadPrint.printSelected = vx.selectedQueue;
                        vx.printSelectedQueue(vx.payloadPrint);
                    }
                }
            } else {
                toastr.error("Please Select Unit First");
            }
        },
        async print(payload) {
            let vx = this;
            if (payload.printSelected) {
                vx.printSelectedQueue(payload);
            } else {
                const date = new Date().toISOString().split("T");
                let newPatient = {
                    date: date[0],
                    queueType: "MANUAL",
                    queueUnitId: `U${sessionStorage.getItem("selectedUnit")}`
                };
                let response = await QueueResource.createQueueUserAuto(
                    newPatient
                );
                vx.detailPrint.displayName1 =
                    response.data.queueUnit.displayName1;
                vx.detailPrint.displayName2 = response.data.queueUnit
                    .displayName2
                    ? response.data.queueUnit.displayName2
                    : "";
                vx.detailPrint.queueNo = response.data.queueNoFormatted;
                vx.detailPrint.content = `{"content": "${
                    response.data.encryptedId
                }","type":"antrian"}`;
            }

            await ServerTimestampRepository.get().then(response => {
                var dateFormattedId = moment(response.data).format(
                    "dddd, D MMMM YYYY HH:mm:ss"
                );
                vx.detailPrint.date = dateFormattedId;
            });
            this.$refs.detailPrint.printQueue(payload);
            //              const printDiv = document.getElementById('divToPrint')
        },
        async printSelectedQueue(payload) {
            let vx = this;
            vx.detailPrint.displayName1 =
                payload.printSelected.queueUnit.displayName1;
            vx.detailPrint.displayName2 = payload.printSelected.queueUnit
                .displayName2
                ? payload.printSelected.queueUnit.displayName2
                : "";
            vx.detailPrint.queueNo = payload.printSelected.queueNoFormatted;
            vx.detailPrint.content = `{"content": "${
                payload.printSelected.encryptedId
            }","type":"antrian"}`;

            await ServerTimestampRepository.get().then(response => {
                var dateFormattedId = moment(response.data).format(
                    "dddd, D MMMM YYYY HH:mm:ss"
                );
                vx.detailPrint.date = dateFormattedId;
            });
            this.$refs.detailPrint.printSelectedQueue(payload);
            //                  const printDiv = document.getElementById('divToPrint')
        },
        async callManual(searchQueueNoFormatted) {
            await this.getHistoryQueue(this.queueDevice.queueUnits[0].id);
            const queueData = this.checkedinQueueResource
                .concat(this.callingQueueResource)
                .concat(this.historyUnitQueue);
            const selectedManual = queueData.find(
                o =>
                    o.queueNoFormatted ===
                    `${searchQueueNoFormatted.toUpperCase()}`
            );
            if (selectedManual) {
                let data = {
                    note: this.note,
                    nextQueueNoFormatted: searchQueueNoFormatted,
                    queueUnitId: selectedManual.queueUnit.id,
                    uniqueId: this.queueDevice.uniqueId
                };
                this.postManualCallResource(data);
                this.nomorAntrian = null;
            } else {
                toastr.error(
                    "Nomor Antrian Tidak ditemukan, Mohon cek kembali Nomor Antrian"
                );
            }
        },
        countUpTime() {
            this.detik = Number(this.detik);
            this.menit = Number(this.menit);
            this.jam = Number(this.jam);
            this.detik < 10 ? (this.zero1 = 0) : (this.zero1 = null);
            this.menit < 10 ? (this.zero2 = 0) : (this.zero2 = null);
            this.jam < 10 ? (this.zero3 = 0) : (this.zero3 = null);

            if (this.detik > 59) {
                this.menit += (this.detik - (this.detik % 60)) / 60;
                this.detik = this.detik % 60;
            }
            if (this.menit > 59) {
                this.jam += (this.menit - (this.menit % 60)) / 60;
                this.menit = this.menit % 60;
            }

            setTimeout(() => {
                this.detik += 1;
                this.detik = this.pad(this.detik);
                this.countUpTime();
            }, 1000);
        },
        pad(num) {
            return ("0" + num).slice(-2);
        },
        toggle() {
            this.$emit("clickToggle", true);
        },
        eventClickBox(data) {
            this.setSelectedQueue(data);
        },
        getClass(queueStatus) {
            return helpers.setIconCaller(queueStatus);
        },
        getType(queueType) {
            switch (queueType) {
                case "AUTO":
                    return "fas fa-mobile-alt";
                default:
                    return "";
            }
        },
        timeFormat(date, index) {
            // this.$set(this.countUps, index, {
            //     jam: 0,
            //     menit: 0,
            //     detik: 0
            // });
            // let now = moment(new Date());
            // let end = moment(date);
            // let duration = moment.duration(now.diff(end));
            // if (!this.countUps[index]) {
            //     this.countUps[index].jam = duration.hours();
            //     this.countUps[index].menit = duration.minutes();
            //     this.countUps[index].detik = duration.seconds();
            // } else {
            //     this.$set(this.countUps[index], "jam", duration.hours());
            //     this.$set(this.countUps[index], "menit", duration.minutes());
            //     this.$set(this.countUps[index], "detik", duration.seconds());
            // }
            // console.log(this.countUps[index], now, index);
            // return duration.asSeconds();
        }
    },
    mounted() {
        setInterval(() => {
            this.nowTime = new Date();
        }, 1000);
        const ps1 = new PerfectScrollbar("#antrianLayani", {
            wheelPropagation: false
        });
        const ps2 = new PerfectScrollbar("#listAntrian", {
            wheelPropagation: false,
            useBothWheelAxes: true,
            suppressScrollY: true
        });
        if (localStorage.defaultPrinter) {
            this.setDefaultPrinter(localStorage.defaultPrinter);
        }
        if (localStorage.printingOption) {
            this.setPrintingOption(localStorage.printingOption);
        }
        this.onInit();
        this.getServerTime();
        this.countUpTime();
    }
};
</script>

<style lang="scss" scoped></style>
